// src/components/Plans.tsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import CheckIcon from "../../assets/check-icon.svg";
import PaidPlanIcon from "../../assets/PaidPlanIcon.svg";
import FreePlanIcon from "../../assets/FreePlanIcon.svg";
import { useSelector } from "react-redux";
import Navbar from "../home/components/Navbar";
import axiosInstance from "../../axiosInstance";
// import { seleceCurrentUser } from "../../redux/features/auth/authSlice"; // Adjust the path as necessary
// import { RootState } from "../store"; // Adjust the path as needed

interface Plan {
  id: string;
  name: string;
  plan: string;
  description: string;
  price: number;
}

const Plans: React.FC = () => {
  const navigate = useNavigate();

  const plans: Plan[] = [
    // {
    //   id: "plan_Oxn6d4dTEmzZRQ",
    //   name: "Student Plan",
    //   plan: "test",
    //   description: "Access to basic features",
    //   price: 300,
    // },
    {
      id: "plan_ABCDE12345",
      name: "Premium Plan",
      plan: "pro",
      description: "Access to all features",
      price: 450,
    },
    // Add more plans as needed
  ];

  const createOrder = async (plan: string) => {
    const token = localStorage.getItem("access_token"); // Retrieve the token

    if (!token) {
      alert("You must be logged in to create a subscription.");
      navigate("/login"); // Redirect to login if token is not found
      return;
    }

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL}/users/pg/razorpay/createSubscription`,
        {
          plan: plan,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the bearer token in headers
            "Content-Type": "application/json",
          },
        },
      );
      console.log(response.data.data);
      const subscription_id = response.data.data.subscription_id; // Extract 'id' as 'razorpaySubscriptionId'
      const id = response.data.data.id;
      if (!subscription_id) {
        throw new Error("Subscription ID not returned from backend.");
      }

      // Navigate to Payment page with order details
      // In Plans.tsx
      navigate("/payment", {
        state: { order: { subscription_id, id } },
      });
    } catch (error: any) {
      console.error("Error creating subscription:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(`Failed to create subscription: ${error.response.data.message}`);
      } else {
        alert("Failed to create subscription. Please try again.");
      }
    }
  };

  return (
    <div style={styles.container}>
      <h1>Select a Plan</h1>
      {plans.map((plan) => (
        <div key={plan.id} style={styles.planCard}>
          <h2>{plan.name}</h2>
          <p>{plan.description}</p>
          <p> 5$ per month (₹{plan.price}) </p>
          <button style={styles.button} onClick={() => createOrder(plan.plan)}>
            Subscribe
          </button>
        </div>
      ))}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
  },
  planCard: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "20px",
    margin: "10px",
    width: "300px",
    textAlign: "center",
  },
  button: {
    padding: "10px 20px",
    marginTop: "10px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#3399cc",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
  },
};

export default Plans;
