import React, { useEffect, useRef } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNodeHeight,
  updateNodeWidth,
  updateNodeLabel,
  deleteNode,
} from "../../../redux/features/nodes";
import { updateShowComponent } from "../../../redux/features/nodeEditor";
import { AppDispatch, RootState } from "../../../redux/store";

const NodeEditor: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const minWidthRef = useRef<HTMLInputElement>();
  const minHeightRef = useRef<HTMLInputElement>();
  const textRef = useRef<HTMLInputElement>();

  const nodes = useSelector((store: RootState) => store?.nodesState?.nodes);
  const clickedNodeID = useSelector(
    (store: RootState) => store?.nodeEditorState?.clickedNodeID,
  );
  const nodePosition = useSelector(
    (store: RootState) => store?.nodeEditorState?.nodePosition,
  );

  const unit = useSelector(
    (state: RootState) => state.outputEditorButtonState.unit,
  );

  const metersToFeet = 3.28084;
  const feetToMeters = 1 / metersToFeet;

  const getDimensionInSelectedUnit = (dimensionInFeet: number) => {
    if (unit === "meters") {
      return Math.round(dimensionInFeet * feetToMeters); // Convert to meters and round to the nearest integer
    }
    return Math.round(dimensionInFeet); // Keep in feet and round to the nearest integer
  };
  let timeout: any;
  const handleTextFieldValue = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (clickedNodeID !== "none") {
        const node = nodes.find((node) => node.id === clickedNodeID);

        if (node && textRef.current) {
          let newLabel = textRef.current.value;

          // Check if the input is empty
          if (!newLabel.trim()) {
            // If empty, set the label to the node's id
            newLabel = (node.id + 1).toString();
            textRef.current.value = newLabel; // Update the text field to show the node ID
          }

          // Ensure that the first character is always the original label (numeric part)
          const fixedInitialLabel = (data: string) => {
            const match = data.match(/^\d+/); // Match digits at the beginning of the string
            return match ? match[0] : ""; // Return the matched digits or an empty string
          };

          // If the user input is not just the original numeric label
          const checkNewLabel = (data: string) => {
            // If the label has changed, use the new label directly
            return newLabel.startsWith(fixedInitialLabel(newLabel))
              ? newLabel.slice(fixedInitialLabel(newLabel).length)
              : newLabel;
          };

          // Combine the original numeric part and the new label text
          const modifiedLabel =
            fixedInitialLabel(newLabel) + checkNewLabel(newLabel);

          // Update the label in the store
          dispatch(
            updateNodeLabel({
              id: clickedNodeID,
              label: modifiedLabel,
            }),
          );
        }
      }
    }, 200);
  };

  const handleDimensionChange = () => {
    if (clickedNodeID !== "none") {
      const minWidth = minWidthRef.current?.value
        ? parseFloat(minWidthRef.current.value)
        : 3;
      const minHeight = minHeightRef.current?.value
        ? parseFloat(minHeightRef.current.value)
        : 3;

      dispatch(
        updateNodeWidth({
          id: clickedNodeID,
          max: 99999,
          min: minWidth,
        }),
      );
      dispatch(
        updateNodeHeight({
          id: clickedNodeID,
          max: 99999,
          min: minHeight,
        }),
      );
    }
  };

  const onDelete = () => {
    if (clickedNodeID !== "none") {
      dispatch(deleteNode(clickedNodeID));
      dispatch(updateShowComponent(false));
    }
  };

  useEffect(() => {
    if (clickedNodeID !== "none") {
      const node = nodes.find((node) => node.id === clickedNodeID);

      if (node && textRef.current) {
        textRef.current.value = node.label || `${clickedNodeID + 1}`;
      }

      if (minWidthRef.current && minHeightRef.current) {
        minWidthRef.current.value = node?.width?.min?.toString() || "";
        minHeightRef.current.value = node?.height?.min?.toString() || "";
      }
    }
  }, [clickedNodeID, nodes, unit]);

  return (
    <Box
      className="node-editor"
      onMouseEnter={() => {
        window.isHoveringNodeOrEditor = true; // Keep the NodeEditor visible
        dispatch(updateShowComponent(true));
      }}
      onMouseLeave={() => {
        window.isHoveringNodeOrEditor = false; // Start hiding process
        setTimeout(() => {
          if (!window.isHoveringNodeOrEditor) {
            dispatch(updateShowComponent(false)); // Hide if still not hovering
          }
        }, 100);
      }}
      sx={{
        position: "fixed",
        top: nodePosition.top - 155,
        left: nodePosition.left + 85,
        padding: 0.75,
        backgroundColor: "#1C4C82",
        border: "0.01px solid white",
        borderRadius: 2,
        fontFamily: "Poppins",
        zIndex: 1000,
      }}
    >
      <TextField
        variant="standard"
        margin="dense"
        inputRef={textRef}
        defaultValue={
          clickedNodeID !== "none" ? nodes[clickedNodeID]?.label : ""
        }
        onChange={handleTextFieldValue}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: "transparent",
            color: "white",
            fontSize: "1rem",
            textAlign: "center",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontFamily: "Poppins",
            fontWeight: 500,
          },
        }}
      />
      <Typography
        variant="h6"
        sx={{
          marginBottom: 1,
          marginX: 1,
          color: "#879FBA",
          fontSize: ".5rem",
          fontWeight: "400",
          marginTop: "-4px",
        }}
      >
        Double Click to Rename
      </Typography>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: 1,
          zIndex: 10,
          borderRadius: 0.5,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: -0.5,
            color: "#7794B4",
            fontSize: "0.75rem",
            fontWeight: "500",
          }}
        >
          {`Min-Width (in ${unit})`}
        </Typography>
        <TextField
          variant="standard"
          margin="dense"
          inputRef={minWidthRef}
          onChange={handleDimensionChange}
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: "#f2f2f2",
              fontSize: "0.75rem",
              textAlign: "center",
              justifyContent: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
              fontFamily: "Poppins",
              fontWeight: 500,
            },
          }}
        />
        <Typography
          variant="h2"
          sx={{
            marginBottom: -0.5,
            color: "#7794B4",
            fontSize: "0.75rem",
            fontWeight: "500",
          }}
        >
          Min-Length (in {unit})
        </Typography>
        <TextField
          variant="standard"
          margin="dense"
          inputRef={minHeightRef}
          onChange={handleDimensionChange}
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: "#f2f2f2",
              fontSize: "0.75rem",
              textAlign: "center",
              justifyContent: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
              fontFamily: "Poppins",
              fontWeight: 500,
            },
          }}
        />
      </Box>
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "#ED4337",
          fontFamily: "Poppins",
          fontSize: "0.75rem",
          fontWeight: 500,
        }}
        onClick={onDelete}
      >
        Delete Node
      </button>
    </Box>
  );
};

export default NodeEditor;
