import React, { MutableRefObject, useState } from "react";
/* import axios from "axios"; */
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Logo from "../../../assets/logo-svg.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ShowInputIcon from "../../../assets/ShowInputIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setAssetType } from "../../../redux/features/appState";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import * as d3 from "d3";
import jsPDF from "jspdf";

interface TopNavProps {
  svgRef: MutableRefObject<SVGSVGElement | null>;
}

const TopNav: React.FC<TopNavProps> = ({svgRef}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isComponentVisible, setComponentVisible] = useState(false);
  const [isRenameMode, setRenameMode] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("Untitled Document 1");
  const dispatch = useDispatch();
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const rooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.rooms,
  );

  const selectedFloorPlan = useSelector(
    (state: RootState) => state.graph.selectedFloorPlan,
  );

  const graphData = useSelector((state: RootState) => state.graph.graphData);

  const postData = () => {
    const url = `${process.env.REACT_APP_BASEURL}/api/document/`;
    let nodesNew: any[] = [];
    if (graphData) {
      graphData.nodes.forEach((ele) => {
        const height = visualViewport?.height;
        const width = visualViewport?.width;
        if (width && height)
          nodesNew.push({ ...ele, x: ele.x / width, y: ele.y / height });
      });
      const data = {
        name: documentTitle,
        floorplans: selectedFloorPlan,
        graph_saved_data: { ...graphData, nodes: nodesNew },
      };
      console.log(data);
      const token = localStorage.getItem("access_token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      axiosInstance
        .post(url, data, { headers })
        .then((response) => {})
        .catch((error) => {
          console.error("Error:", error);
        });

      setAnchorEl(null);
    }
  };
  const goToFiles = () => {
    navigate("/home");
  };

  const goToInputeditor = () => {
    navigate("/inputeditor");
  };
  const handleComponentButtonClick = () => {
    dispatch(setAssetType("window"));
  };

  const handleRenameClick = () => {
    setRenameMode(true);
  };

  const handleDocumentTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDocumentTitle(event.target.value);
  };

  const handleTitleDoubleClick = () => {
    setRenameMode(true);
  };

  const handleTitleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && documentTitle !== "") {
      setRenameMode(false);
    }
  };

  const handleTitleComplete = () => {
    if (documentTitle !== "") {
      setRenameMode(false);
    }
  };

  function loadImageAsBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // To handle cross-origin issues
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return reject("Canvas context is not available");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const base64Image = canvas.toDataURL("image/png");
        resolve(base64Image);
      };
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  }

  const savePdf = async () => {
    const svgElement = svgRef.current;
    if (!svgElement) return;

    // Select the SVG container and reset zoom
    const svgD = d3
      .select<SVGSVGElement, unknown>(svgElement)
      .attr("width", "100%")
      .attr("height", "100%");

    const mainGroup = svgD.select(".main-group");

    // Reset zoom (Optional)
    const zoomBehavior = d3.zoom<SVGSVGElement, unknown>();
    zoomBehavior.transform(
      svgD as unknown as d3.Selection<SVGSVGElement, unknown, any, any>,
      d3.zoomIdentity,
    );
    mainGroup.attr("transform", null);

    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "a4",
    });

    const pageWidth = pdf.internal.pageSize.getWidth(); // A4 page width in points
    const pageHeight = pdf.internal.pageSize.getHeight(); // A4 page height in points

    // Get all image elements in the SVG
    const images = svgElement.querySelectorAll("image");

    // Load all images as base64
    const imagePromises = Array.from(images).map((image) => {
      const imageUrl = image.getAttribute("href");
      if (!imageUrl) return Promise.resolve(); // Skip if no href
      return loadImageAsBase64(imageUrl).then((base64Image) => {
        // Replace href with base64 image
        image.setAttribute("href", base64Image);
      });
    });

    // Wait for all images to load and replace href attributes with base64
    await Promise.all(imagePromises);

    // Now convert SVG to a string
    const svgAsString = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgAsString], { type: "image/svg+xml" });
    const svgUrl = URL.createObjectURL(svgBlob);

    // Create a canvas to render the SVG into
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      // Get the SVG bounding box dimensions
      const { width: originalWidth, height: originalHeight } =
        svgElement.getBoundingClientRect();

      // Calculate scale factor to fit SVG to A4 width, maintaining aspect ratio
      const scale = pageWidth / originalWidth;
      const scaledHeight = originalHeight * scale;

      // Set canvas size to match the SVG size
      canvas.width = originalWidth;
      canvas.height = originalHeight;

      if (ctx) {
        // Draw the SVG as an image on the canvas
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

        // Convert canvas to PNG image data URI
        const imgData = canvas.toDataURL("image/png");

        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 0, 0, pageWidth, scaledHeight);    
        pdf.save("outputfloorplan.pdf");
      }

      // Clean up the object URL
      URL.revokeObjectURL(svgUrl);
    };

    // Trigger loading of the SVG image
    img.src = svgUrl;
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "inherit",
        marginTop: "20px",
        boxShadow: "none",
        cursor: "default",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "white",
          borderRadius: "6px",
          width: "95%",
          boxShadow: "0px 0px 24.883197784423828px 0px rgba(3, 34, 44, 0.1)",
        }}
      >
        <IconButton
          sx={{
            backgroundColor: "#1C4C82",
            padding: ".5rem 1rem",
            borderRadius: "6px",
            "&:hover": { backgroundColor: "#1C4C82" },
          }}
          onClick={handleMenuOpen}
        >
          <img src={Logo} alt="GPlan" />
          <ExpandMoreIcon
            sx={{ color: "white", marginLeft: "8px", fontWeight: 200 }}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={goToFiles}>Go To All Files</MenuItem>
          {/* <Divider sx={{ my: 0.5 }} /> */}
          {/* <MenuItem onClick={goToInputeditor}>New File</MenuItem> */}
          {/* <Divider sx={{ my: 0.5 }} /> */}
          <MenuItem onClick={postData}>Save File</MenuItem>
          <MenuItem onClick={savePdf}>Export as PDF</MenuItem>
          {/* <Divider sx={{ my: 0.5 }} /> */}
          <MenuItem onClick={goToInputeditor}>View Graph</MenuItem>
          {/* <Divider sx={{ my: 0.5 }} /> */}
          {/* <MenuItem onClick={handleMenuClose}>Export</MenuItem> */}
          {/* <Divider sx={{ my: 0.5 }} /> */}
          <MenuItem onClick={goToFiles}>Exit</MenuItem>
        </Menu>
        <Button
          sx={{
            backgroundColor: "inherit",
            color: "#333333",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
            textTransform: "none",
            marginLeft: "36px",
            padding: ".5rem 1rem",
          }}
          onClick={handleComponentButtonClick}
        >
          Assets
        </Button>
        <Typography
          variant="h6"
          style={{
            flexGrow: 1,
            textAlign: "center",
            color: "black",
            fontFamily: "Montserrat",
            fontSize: 16,
            fontWeight: 600,
            cursor: "pointer",
          }}
          onDoubleClick={handleTitleDoubleClick}
        >
          {isRenameMode ? (
            <>
              <input
                type="text"
                value={documentTitle}
                onChange={handleDocumentTitleChange}
                onKeyPress={handleTitleKeyPress}
                onBlur={handleTitleComplete}
                style={{
                  border: "none",
                  outline: "none",
                  minWidth: "150px",
                  borderBottom: "2px solid black",
                  color: "black",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              />
              <DoneRoundedIcon
                sx={{
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
                onClick={handleTitleComplete}
              />
            </>
          ) : (
            <>
              {documentTitle}
              <EditOutlinedIcon
                sx={{
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
                onClick={handleTitleDoubleClick}
              />
            </>
          )}
        </Typography>
        <Button
          sx={{
            backgroundColor: "#1C4C82",
            padding: ".75rem 1rem",
            borderRadius: "5px",
            "&:hover": { backgroundColor: "#1C4C82" },
          }}
          onClick={() => console.log("Button on the right clicked")}
        >
          <img src={ShowInputIcon} alt="Show Input" />
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
