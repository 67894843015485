import { Box, Switch, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useDispatch } from "react-redux";
import { toggleUnit } from "../redux/features/appState";
import {
  Nodes,
  updateNodeHeight,
  updateNodeWidth,
  updatePlotHeight,
  updatePlotWidth,
} from "../redux/features/nodes";

const DimensionsToggle = () => {
  const isFirstRender = useRef(true);


  const dispatch = useDispatch();
  const plotWidth = useSelector((state: any) => state.nodesState.plotWidth);
  const plotHeight = useSelector((state: any) => state.nodesState.plotHeight);
  const nodes = useSelector((state: any) => state.nodesState.nodes);

  const unit = useSelector(
    (state: RootState) => state.outputEditorButtonState.unit,
  );
  const handleToggleDimensions = useCallback(() => {
    dispatch(toggleUnit());
  }, [dispatch]);

  const getDimensionInSelectedUnit = (dimension: number) => {
    const feetToMeters = 0.3048;
    const metersToFeet = 1 / feetToMeters;
    return unit === "meters"
      ? Math.round(dimension * feetToMeters)
      : Math.round(dimension * metersToFeet);
  };


  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    dispatch(updatePlotWidth(getDimensionInSelectedUnit(plotWidth)));
    dispatch(updatePlotHeight(getDimensionInSelectedUnit(plotHeight)));
    nodes.forEach((node: Nodes) => {
      const newMinWidth = getDimensionInSelectedUnit(node.width.min);
      const newMinHeight = getDimensionInSelectedUnit(node.height.min);

      // Update minWidth
      dispatch(
        updateNodeWidth({
          id: node.id,
          max: node.width.max, // Keep max unchanged
          min: newMinWidth, // Update min
        }),
      );

      // Update minHeight
      dispatch(
        updateNodeHeight({
          id: node.id,
          max: node.height.max, // Keep max unchanged
          min: newMinHeight, // Update min
        }),
      );
    });
  }, [unit]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ffffff",
        padding: "5px",
        borderRadius: "8px",
        border: "1px solid #E0E0E0",
      }}
    >
      <Typography
        sx={{
          color: unit === "feet" ? "#1C4C82" : "#333333",
          fontSize: "16px",
          fontWeight: 600,
          pr: 1,
        }}
      >
        Feet
      </Typography>
      <Switch
        checked={unit === "meters"}
        onChange={handleToggleDimensions}
        sx={{
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#1C4C82",
            "&:hover": {
              backgroundColor: "rgba(28, 76, 130, 0.08)",
            },
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#1C4C82",
          },
        }}
      />
      <Typography
        sx={{
          color: unit === "meters" ? "#1C4C82" : "#333333",
          fontSize: "16px",
          fontWeight: 600,
          pl: 1,
        }}
      >
        Meters
      </Typography>
    </Box>
  );
};

export default DimensionsToggle;
